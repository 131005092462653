.image {
    height: 39.375rem;
    object-fit: cover;
}

.carousel-center-caption {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    height: 100%;
    width: 100%;
}

.carousel-center-caption-inner {
    display: table;
    position: relative;
    top: 32%;
    text-align: center;
    margin: 0 auto;
    max-width: 50rem;

    @media screen and (min-width: 768px) {
        text-align: left;
        margin: 0;
    }
}

.carousel .link {
    max-width: 12.5rem;
}
