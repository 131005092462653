.navbar {
  min-height: 5em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.175);

  .navbar-nav .nav-link {
    &:after {
      display: block;
      content: "";
      border-bottom: solid 2px #1b7fb4;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &:hover:after {
      transform: scaleX(1);
    }
  }
}
