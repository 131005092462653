.fa-stack {
    margin-left: -0.563rem;

    .fas.fa-circle {
        color: rgba(0, 0, 0, 0.2);
    }
}

@media (max-width: 767px) {
    .image-container img {
        float: left !important;
    }
}
