.d-grid {
    display: grid;
}

.border-dash {
    border-style: dashed !important;
}

.font-weight-extra-light {
    font-weight: 200;
}

.direction-rtl {
    direction: rtl;
}

.direction-ltr {
    direction: ltr;
}

@media (min-width: 576px) and (max-width: 991px) {
    .container {
      max-width: 100%;
    }
}
