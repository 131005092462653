.card-grid {
    grid-template-columns: repeat(1, 1fr);
}

.card-grid-2 {
    @media (min-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.card-grid-3 {
    @extend .card-grid-2;
    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

.card-grid-4 {
    @extend .card-grid-3;
    @media (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr) !important;
    }
}
