body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:hover, a:focus, a:focus-within {
  color: inherit;
}

// Custom text colros
.color-primary {
  color: #1B7FB4;
}

.color-secondary {
  color: #3ab95f;
}


// Custom background colors
.bg-color-primary {
  background-color: #1B7FB4;
}

.bg-color-secondary {
  background-color: #3ab95f;
}

.bg-color-alice-blue {
  background-color: #F3F6FB;
}

.bg-color-alice-blue-095 {
  background-color: rgba(243, 246, 251, 0.95);
}

.bg-light-grey {
  background-color: #f2f4f6;
}

.bg-light-grey2 {
  background-color: #F6F6F6;
}

// Custom background gradiants
.bg-gradient {
  background: linear-gradient(to right, #3ab95f 0%, #1b7fb4 50%);
}

// Custom border colors
.border-color-primary {
  border-color: #1B7FB4 !important;
}

.border-custom-1 {
  border-bottom: 1px solid rgba(0, 0, 0, .175);
}

// Custom shadow colors
.box-custom-shadow-1 {
  box-shadow: 0 1.25rem 40px rgba(0, 0, 0, .1);
}

.box-custom-shadow-2 {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}

// border styles
.border-bottom-1px {
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
}

// Custom title stlyes
.title-bar {
  display: inline-block;
  position: relative;
  padding-bottom: 1.875rem;

  &::before {
    content: "";
    background: #3d5ba1;
    position: absolute;
    bottom: 0;
    width: 4.375rem;
    height: 0.25rem;
  }
}

.title-span {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1.0625rem;
  text-transform: uppercase;
  color: #1B7FB4;
  display: inline-block;
  position: relative;
  padding-left: 4.25rem;

  &::before {
    position: absolute;
    content: "";
    width: 3.375rem;
    height: 0.125rem;
    background: #1B7FB4;
    top: 0;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.title-span2 {
  display: inline-block;
  margin-bottom: 0;
  color: #1B7FB4;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.title-span3 {
  color: #fff;
  padding: 0.125rem 1.25rem 0.0625rem;
  display: inline-block;
  background: #5f5753;
  border-radius: 1.25rem;
  text-transform: uppercase;
  font-size: 0.6875rem;
  font-weight: 400;
}

// Custom background design for icon
.icon-bg-design {
  background-color: #F3F6FB;
  width: 9.0625rem;
  height: 6.875rem;
  font-size: 2.625rem;
  color: #303030;
  text-align: center;
  line-height: 6.875rem;
  border-radius: 5rem 12.5rem 12.5rem 22.625rem;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;

  &:hover {
    background-color: #1B7FB4;
    color: #ffffff;
    -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
  }
}

// Custom background design without color for icon
.icon-bg-circle {
  flex: none;
  width: 3.75rem;
  height: 3.75rem;
  font-size: 1.625rem;
  text-align: center;
  line-height: 3.625rem;
  border-radius: 50%;
}

// Custom overlay backdrop for image
.img-overlay {
  position: relative;
  display: block;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
  }
}
