
.step-number {
	direction: ltr;
	
	> span {
		text-align: center;
		margin-right: 1.25rem;
		background: #1b7fb4;
	}
	> span:not(.step-name) {
		flex: 0 0 4.375rem;
		line-height: 4.375rem;
		height: 4.375rem;
		width: 4.375rem;
		font-size: 1.625rem;
		border-radius: 50%;
	}
	> span.step-name {
		flex: 0 0 9.375rem;
		align-items: center;
		display: flex;
		padding: 1.25rem;
	}
}
